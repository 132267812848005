<script setup lang="ts">
import { IconBtn, Menu } from 'webcc-ui-components'

const { $tracking } = useNuxtApp()
const { getLocaleMessage, fallbackLocale } = useI18n()

function openPanel(on: { click: () => void }) {
  $tracking.trackSupportCenter({
    action: $tracking.action.Open,
    label: getLocaleMessage(fallbackLocale.value)[
      'components.common.supportCenter'
    ],
  })
  on?.click()
}
</script>

<template>
  <div class="pointer-events-none">
    <Menu
      :offset="8"
      placement="top-end"
      menu-class="rounded-t-xl"
      class="support-center-button pointer-events-auto"
    >
      <template #activator="{ on, open }">
        <div class="h-11">
          <IconBtn
            class="h-[44px]"
            data-id="support-center-button-desktop"
            icon="chat-question"
            variant="floating"
            size="lg"
            :is-active="open"
            @click="openPanel(on)"
          />
        </div>
      </template>

      <template #default="{ on }">
        <header
          class="flex max-h-14 items-center justify-end border-b border-bgr-200 px-6 py-4"
        >
          <p class="flex grow justify-center text-base font-medium leading-5">
            {{ $t('components.common.supportCenter') }}
          </p>

          <IconBtn
            class="close-button"
            variant="transparent"
            icon="arrow-down"
            v-on="on"
          />
        </header>

        <SupportCenterMenu
          class="grid gap-y-2 px-10 py-8 md:min-w-[375px]"
          @click="on?.click()"
        />
      </template>
    </Menu>
  </div>
</template>

<style scoped>
:deep(.close-button) svg {
  color: theme('colors.txt.400');
}
</style>
