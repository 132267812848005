import { useInfiniteQuery, useQueryClient } from '@tanstack/vue-query'
import { pick } from 'lodash-es'
import type { MaybeRef, Ref, ToRefs } from 'vue'
import { toRefs } from '@vueuse/core'
import { isToday, startOfDay } from 'date-fns'
import { normalizeReservation } from './reservation'
import { useApiFetch } from './api'
import {
  type paths,
  type components,
  PathsApiReservationsGetParametersQuerySort as ReservationsQuerySort,
  PathsApiReservationsGetParametersQueryRangeType as ReservationsQuery,
} from '~/schema'

export type ReservationsResponse =
  components['schemas']['PaginationReservationDetails']
export type Reservation = components['schemas']['ReservationDetails']
export type ArrivalDepartureDates =
  components['schemas']['ArrivalDepartureDates']
export type NumberTravellers = components['schemas']['NumberTravellers']
export type NormalizedReservation = Reservation & {
  email: string
  phone: string
  showTravellerContact?: boolean
  lastDateChanges?: ArrivalDepartureDates | undefined
  lastPaxChanges?: NumberTravellers | undefined
  customerName?: string
}

export type ReservationsRequestQuery = NonNullable<
  paths['/api/reservations']['get']['parameters']['query']
>

export type ReservationsPagination = Required<
  Pick<ReservationsResponse, 'page' | 'size' | 'total' | 'rest'>
>

const RESERVATION_PAGE_SIZE = 12

const defaultPagination: ReservationsPagination = {
  page: 0,
  size: RESERVATION_PAGE_SIZE,
  total: 0,
  rest: 0,
}

export type ReservationsFilters = {
  dateFrom?: string
  dateTo?: string
  properties?: string[]
}

export enum ReservationType {
  ARRIVALS = 'arrivals',
  DEPARTURES = 'departures',
}

export function useReservationsQuery(
  type: MaybeRef<ReservationType>,
  options?: (ToRefs<ReservationsFilters> | ReservationsFilters) & {
    enabled?: Ref<boolean>
  },
) {
  const { locale } = useI18n()
  const fetch = useApiFetch()
  const queryClient = useQueryClient()
  const queryResult = useInfiniteQuery({
    enabled: computed<boolean>(() => unref(options?.enabled) !== false),
    queryKey: [
      'reservations',
      locale,
      type,
      options?.dateFrom,
      options?.dateTo,
      options?.properties,
    ],
    initialPageParam: 0,
    async queryFn({
      signal,
      pageParam = 0,
      queryKey: [_, _locale, typeRef, fromRef, toRef, propertiesRef],
    }) {
      const dateFrom = fromRef ? (toValue(fromRef) as string) : undefined
      const dateTo = toRef ? (toValue(toRef) as string) : undefined
      const properties = propertiesRef
        ? (toValue(propertiesRef) as string[])
        : undefined
      return await fetch<ReservationsResponse>('/api/reservations', {
        signal,
        query: {
          ...(toValue(typeRef) === ReservationType.DEPARTURES
            ? {
                sort: ReservationsQuerySort.departure,
                rangeType: ReservationsQuery.departureDate,
              }
            : {
                sort: ReservationsQuerySort.arrival,
                rangeType: ReservationsQuery.arrivalDate,
              }),
          page: pageParam,
          size: RESERVATION_PAGE_SIZE,
          dateFrom,
          dateTo,
          properties,
        } as ReservationsRequestQuery,
      })
    },
    getNextPageParam(lastPageData, _allPages, lastPageParam) {
      if (!lastPageData.rest) return
      return lastPageParam + 1
    },
    select({ pages }) {
      const reservations = pages
        .reduce(
          (acc, current) => acc.concat(current.content || []),
          [] as Reservation[],
        )
        .map(normalizeReservation)
      return {
        reservations,
        todayReservations: countTodayReservations(type, reservations),
        pagination: pick(pages[pages.length - 1] || defaultPagination, [
          'page',
          'size',
          'total',
          'rest',
        ]) as ReservationsPagination,
      }
    },
  })

  watchImmediate(queryResult.data, (currentData) => {
    if (!currentData) return
    currentData.reservations.forEach((res) => {
      queryClient.setQueryData(['reservation', res.resNo], res)
      queryClient.invalidateQueries({ queryKey: ['reservation', res.resNo] })
    })
  })

  return queryResult
}

const defaultFilters: ReservationsFilters = {
  dateFrom: undefined,
  dateTo: undefined,
  properties: [],
}

export function useReservationsFilters() {
  const values = useState('reservation-filters', () => defaultFilters)

  return {
    values,
    ...toRefs(values),
    isApplied: computed(() => {
      const { properties, dateTo, dateFrom } = values.value
      return properties?.length || (dateFrom && dateTo)
    }),
    setFilters(filters: ReservationsFilters) {
      values.value = filters
    },
    setDates(
      dateFrom: string | Date | undefined,
      dateTo: string | Date | undefined,
    ) {
      values.value = {
        ...values.value,
        dateFrom:
          dateFrom instanceof Date
            ? dateFrom.toISOString().split('T')[0]
            : dateFrom,
        dateTo:
          dateTo instanceof Date ? dateTo.toISOString().split('T')[0] : dateTo,
      }
    },
    setProperties(properties: string[] | undefined) {
      values.value = {
        ...values.value,
        properties,
      }
    },
    reset() {
      values.value = defaultFilters
    },
  }
}

function countTodayReservations(
  type: MaybeRef<ReservationType>,
  reservations?: NormalizedReservation[],
) {
  if (!reservations) return 0
  return reservations.reduce((acc, reservation) => {
    const arrivalDate = startOfDay(new Date(reservation.arrivalDate))
    const departureDate = startOfDay(new Date(reservation.departureDate))

    if (
      (unref(type) === ReservationType.ARRIVALS && isToday(arrivalDate)) ||
      (unref(type) === ReservationType.DEPARTURES && isToday(departureDate))
    ) {
      acc++
    }
    return acc
  }, 0)
}
