import revive_payload_client_OG7igXFEyI from "/myapp/node_modules/.pnpm/nuxt@3.13.0_eslint@8.56.0_rollup@4.21.1_typescript@5.4.5_vite@5.4.6/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_waIMjNHVVS from "/myapp/node_modules/.pnpm/nuxt@3.13.0_eslint@8.56.0_rollup@4.21.1_typescript@5.4.5_vite@5.4.6/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_86xZIGMgpG from "/myapp/node_modules/.pnpm/nuxt@3.13.0_eslint@8.56.0_rollup@4.21.1_typescript@5.4.5_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import auth_plugin_SDs6qgbn1j from "/myapp/src/modules/auth/runtime/auth.plugin.ts";
import runtime_plugin_6fa86710_8fsBHu8DZs from "/myapp/.nuxt/runtime.plugin.6fa86710.ts";
import consent_plugin_OrpQzVK0m0 from "/myapp/src/modules/consent/runtime/consent.plugin.ts";
import route_bQWBGeaCfs from "/myapp/src/plugins/route.ts";
import payload_client_L1KcW7rqJ3 from "/myapp/node_modules/.pnpm/nuxt@3.13.0_eslint@8.56.0_rollup@4.21.1_typescript@5.4.5_vite@5.4.6/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_d9clkrIcCr from "/myapp/node_modules/.pnpm/nuxt@3.13.0_eslint@8.56.0_rollup@4.21.1_typescript@5.4.5_vite@5.4.6/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_D2Ujn13zXb from "/myapp/node_modules/.pnpm/nuxt@3.13.0_eslint@8.56.0_rollup@4.21.1_typescript@5.4.5_vite@5.4.6/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_ejKcXDIdS7 from "/myapp/node_modules/.pnpm/nuxt@3.13.0_eslint@8.56.0_rollup@4.21.1_typescript@5.4.5_vite@5.4.6/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/myapp/.nuxt/components.plugin.mjs";
import prefetch_client_zcRTmIKGrA from "/myapp/node_modules/.pnpm/nuxt@3.13.0_eslint@8.56.0_rollup@4.21.1_typescript@5.4.5_vite@5.4.6/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nps_plugin_client_HPfIki6HrT from "/myapp/src/modules/nps/runtime/nps.plugin.client.ts";
import pull_to_refresh_client_3iAGLApR6u from "/myapp/src/modules/pwa/runtime/pull-to-refresh.client.ts";
import webpush_plugin_UOAqjYppRY from "/myapp/.nuxt/webpush.plugin.js";
import banner_plugin_client_f7qDt2BrYH from "/myapp/src/modules/pwa/runtime/banner.plugin.client.ts";
import pwa_icons_plugin_LnrhIJeMG7 from "/myapp/.nuxt/pwa-icons-plugin.ts";
import pwa_client_EvYNSq4cj0 from "/myapp/node_modules/.pnpm/@vite-pwa+nuxt@0.10.1_rollup@4.21.1_vite@5.4.6_workbox-build@7.1.1_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import unleash_plugin_client_z11R0dBxXd from "/myapp/src/modules/unleash/runtime/unleash.plugin.client.ts";
import i18n_cFUM2k0LJt from "/myapp/node_modules/.pnpm/@nuxtjs+i18n@8.2.0_rollup@4.21.1_vue@3.4.38/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import currency_5iM1SYKP2O from "/myapp/src/plugins/currency.ts";
import error_t9vrANEheK from "/myapp/src/plugins/error.ts";
import gtm_JE2y3coDIz from "/myapp/src/plugins/gtm.ts";
import hydration_client_7uir9Lpz2B from "/myapp/src/plugins/hydration.client.ts";
import sentry_client_KAXFuL2wum from "/myapp/src/plugins/sentry.client.ts";
import sse_qpzEVq9hwa from "/myapp/src/plugins/sse.ts";
import toast_ammHSktxCk from "/myapp/src/plugins/toast.ts";
import touch_clfFFfMVxr from "/myapp/src/plugins/touch.ts";
import vue_query_JC9gnf7CA1 from "/myapp/src/plugins/vue-query.ts";
import auth_events_mgogFCKzZc from "/myapp/src/plugins/auth-events.ts";
import date_LNdv6DCjtO from "/myapp/src/plugins/date.ts";
import remember_me_o8wXFBwayj from "/myapp/src/plugins/remember-me.ts";
import tracking_client_6yZzhGFSmD from "/myapp/src/plugins/tracking.client.ts";
import translations_keys_xEeDYLqZP6 from "/myapp/src/plugins/translations-keys.ts";
export default [
  revive_payload_client_OG7igXFEyI,
  unhead_waIMjNHVVS,
  router_86xZIGMgpG,
  auth_plugin_SDs6qgbn1j,
  runtime_plugin_6fa86710_8fsBHu8DZs,
  consent_plugin_OrpQzVK0m0,
  route_bQWBGeaCfs,
  payload_client_L1KcW7rqJ3,
  navigation_repaint_client_d9clkrIcCr,
  check_outdated_build_client_D2Ujn13zXb,
  chunk_reload_client_ejKcXDIdS7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_zcRTmIKGrA,
  nps_plugin_client_HPfIki6HrT,
  pull_to_refresh_client_3iAGLApR6u,
  webpush_plugin_UOAqjYppRY,
  banner_plugin_client_f7qDt2BrYH,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_EvYNSq4cj0,
  unleash_plugin_client_z11R0dBxXd,
  i18n_cFUM2k0LJt,
  currency_5iM1SYKP2O,
  error_t9vrANEheK,
  gtm_JE2y3coDIz,
  hydration_client_7uir9Lpz2B,
  sentry_client_KAXFuL2wum,
  sse_qpzEVq9hwa,
  toast_ammHSktxCk,
  touch_clfFFfMVxr,
  vue_query_JC9gnf7CA1,
  auth_events_mgogFCKzZc,
  date_LNdv6DCjtO,
  remember_me_o8wXFBwayj,
  tracking_client_6yZzhGFSmD,
  translations_keys_xEeDYLqZP6
]