<script setup lang="ts">
import { vOnClickOutside } from '@vueuse/components'
import type { LocaleObject } from '@nuxtjs/i18n'

const props = withDefaults(
  defineProps<{
    locales: Array<LocaleObject>
    locale: string
    autoFocus?: boolean
  }>(),
  {
    autoFocus: false,
  },
)

const emit = defineEmits<{
  (e: 'click'): void
  (e: 'close'): void
}>()

const buttonRefs = ref<HTMLDivElement[]>()

const { setLocale, setLocaleCookie } = useI18n()
const { trackLanguageChange } = useHeaderTracking()

function handleClick(selectedLocale: LocaleObject) {
  trackLanguageChange(selectedLocale.name)
  setLocale(selectedLocale.code)
  setLocaleCookie(selectedLocale.code)
  emit('click')
}

onMounted(async () => {
  if (props.autoFocus) {
    await nextTick()
    buttonRefs.value?.[0].focus()
  }
})
</script>

<template>
  <div
    ref="containerRef"
    v-on-click-outside="() => $emit('close')"
    data-id="language-button-list"
    class="flex flex-col"
  >
    <button
      v-for="currLocale in locales"
      ref="buttonRefs"
      :key="currLocale.code"
      class="w-full h-10 box-border inline-flex items-center justify-between px-6 hover:text-thm border-b border-txt-700 focus:relative focus:border-none last:border-none"
      :class="{
        'bg-bgr-100 border-thm': currLocale.code === locale,
      }"
      @click="handleClick(currLocale)"
    >
      <span
        class="font-normal text-sm leading-4.5"
        :class="{
          'text-thm': currLocale.code === locale,
          'text-txt-300': currLocale.code !== locale,
        }"
      >
        {{ currLocale.name }}
      </span>

      <span class="font-medium text-sm uppercase leading-3.5 text-txt-300">
        {{ currLocale.code }}
      </span>
    </button>
  </div>
</template>
