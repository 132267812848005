import { useMutation, useQuery, useQueryClient } from '@tanstack/vue-query'
import type { MaybeRef, MaybeRefOrGetter } from 'vue'
import type { DefaultError } from '@tanstack/query-core'
import {
  type components,
  ReservationDatesChangeType,
  ReservationPaxChangeType,
} from '~/schema'
import type {
  NormalizedReservation,
  Reservation,
} from '~/composables/reservations'
import { useApiFetch } from '~/composables/api'
import type { ReservationNotification } from '~/composables/inbox'
import { TravellerContactType } from '~/constants/enums'

type ReservationID = string

export type ReservationDetails = components['schemas']['ReservationDetails']
export type ReservationChangeDetails =
  | components['schemas']['ReservationCreatedChange']
  | components['schemas']['ReservationDatesChange']
  | components['schemas']['ReservationPaxChange']

export function useReservationPopup() {
  const id = useState<ReservationID | null>('reservation-modal-id', () => null)
  const { getLocaleMessage, fallbackLocale } = useI18n()
  const { $tracking } = useNuxtApp()
  const opened = computed(() => !!id.value)
  const { data, isFetched, isError, isLoading, isFetching } =
    useReservationQuery(id)
  const { mutate, isPending } = useReservationDeleteMutation()
  return {
    opened,
    isFetched,
    isError,
    isLoading,
    isFetching,
    reservation: data,
    isRemoving: isPending,
    remove() {
      const reservationId = toValue(id)
      if (!reservationId) return
      return new Promise<void>((resolve, reject) => {
        mutate(reservationId, {
          onSuccess() {
            resolve()
          },
          onError(error) {
            reject(error)
          },
        })
      })
    },
    open(reservationId: MaybeRefOrGetter<ReservationID>) {
      id.value = toValue(reservationId)
    },
    close() {
      id.value = null
    },
    trackReservation(category: string = 'Reservation') {
      $tracking.trackUpcomingBusiness({
        action: $tracking.action.View,
        label: getLocaleMessage(fallbackLocale.value)[
          'components.common.bookingDetails'
        ],
        category,
      })
    },
  }
}

export function useReservationQuery(
  reservationId?: MaybeRefOrGetter<ReservationID | null>,
) {
  const fetch = useApiFetch()
  return useQuery({
    enabled: computed(() => !!toValue(reservationId)),
    queryKey: ['reservation', reservationId],
    networkMode: 'offlineFirst',
    queryFn({ queryKey: [_, id] }) {
      return fetch<ReservationDetails>(`/api/reservations/${toValue(id)}`)
    },
    select(data) {
      return normalizeReservation(data)
    },
  })
}

export function useReservationDeleteMutation() {
  const queryClient = useQueryClient()
  const fetch = useApiFetch()
  return useMutation<boolean, DefaultError, MaybeRef<ReservationID>>({
    mutationFn(reservationId) {
      return fetch<boolean>(`/api/owner-bookings/${toValue(reservationId)}`, {
        method: 'delete',
        body: {},
      })
    },
    onSuccess: (_, reservationId) => {
      // Invalidate and re-fetch
      queryClient.invalidateQueries({ queryKey: ['reservations'] })
      queryClient.invalidateQueries({
        queryKey: ['reservation', reservationId],
      })
      queryClient.invalidateQueries({ queryKey: ['calendar'] })
      queryClient.invalidateQueries({ queryKey: ['inbox-notifications'] })
    },
  })
}

export function normalizeReservation(
  reservation: Reservation,
): NormalizedReservation {
  function getLastChange(changeType: string) {
    if (!reservation.changes || reservation.changes.length === 0) return

    for (let i = reservation.changes.length - 1; i >= 0; i--) {
      const dateChange = reservation.changes[i].changes.find(
        (change: ReservationChangeDetails) => change.type === changeType,
      )

      if (dateChange) return dateChange.previous
    }
  }

  return {
    ...reservation,
    ...convertTravellerContact(reservation.travellerContact),
    showTravellerContact: reservation.travellerContact?.show,
    lastDateChanges: getLastChange(ReservationDatesChangeType.DATES),
    lastPaxChanges: getLastChange(ReservationPaxChangeType.PAX),
    customerName: reservation.travellerName,
  }
}

export function convertTravellerContact(
  travellerContact?: NonNullable<Reservation['travellerContact']>,
) {
  const {
    [TravellerContactType.EMAIL]: email,
    [TravellerContactType.MOB]: mobile,
    [TravellerContactType.PHONE]: phone,
    [TravellerContactType.PHONE_BUSINESS]: phoneBusiness,
  } = Object.fromEntries(
    (travellerContact?.value || []).map(
      ({ contactInfo: { type, contact } }) => [type, contact],
    ),
  )

  const anyPhone = mobile || phone || phoneBusiness

  return { email, phone: anyPhone }
}

export enum UrgencyTag {
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  CurrentGuest = 'CurrentGuest',
  ArrivingInDays = 'ArrivingInDays',
}

export enum ReservationGeneralTag {
  HouseClosing = 'HouseClosing',
  OwnerBooking = 'OwnerBooking',
  MyRent = 'MyRent',
  Comment = 'Comment',
}

export type ReservationTag = Pick<
  ReservationNotification,
  | 'startDate'
  | 'endDate'
  | 'isOwnerBooking'
  | 'isMyRent'
  | 'isCommentsAvailable'
> & { canceled?: boolean; houseClosing?: boolean }

export function convertReservationToReservationTag(
  reservation: Reservation | NormalizedReservation,
) {
  return {
    startDate: reservation.arrivalDate,
    endDate: reservation.departureDate,
    isOwnerBooking: reservation.ownerBooking,
    isMyRent: reservation.myRent,
    houseClosing: reservation.houseClosing,
    canceled: reservation.canceled,
    isCommentsAvailable: reservation.commentsAvailable,
  } as ReservationTag
}
