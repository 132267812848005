export function useHeaderTracking() {
  const { $gtm, $i18n, $tracking } = useNuxtApp()
  const route = useRoute()
  const getCommonParams = () => ({
    event: 'header',
    component: 'header',
    language: unref($i18n.locale),
    position: route.path,
    _clear: true,
  })

  function trackInboxBellClick(highlight: boolean) {
    $gtm.trackEvent({
      action: $tracking.action.Navigate,
      label: 'bell icon',
      category: 'inbox',
      highlight,
      ...getCommonParams(),
    })
  }

  function trackLanguageChange(lang: string) {
    $gtm.trackEvent({
      action: 'change language',
      label: lang,
      category: undefined,
      ...getCommonParams(),
    })
  }

  function trackMyProfileClick() {
    $gtm.trackEvent({
      action: $tracking.action.Navigate,
      label: 'account icon',
      category: 'My Profile',
      ...getCommonParams(),
    })
  }

  function trackLogOutClick() {
    $gtm.trackEvent({
      action: 'logout',
      label: 'account icon',
      category: 'Logout',
      ...getCommonParams(),
    })
  }

  return {
    trackInboxBellClick,
    trackLanguageChange,
    trackMyProfileClick,
    trackLogOutClick,
  }
}
